module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alltagshunde Rheinbach - Carolin Henseler","short_name":"Alltagshunde","start_url":"/","background_color":"#ffffff","theme_color":"#5aa02c","display":"minimal-ui","icon":"content/assets/icon2.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"92f21ddbfb0ce5b92a2f1d0c404fb4a3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@alltagshunde/gatsby-theme-alltagshunde/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
